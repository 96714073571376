import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Navigation from '../containers/navbar.js';
import Partneri from '../containers/partneriComponent';
import Map from '../containers/map.js';
import Footer from '../containers/footer.js';
import Isvg from 'react-inlinesvg';
import email from '../images/SVG/email.svg';
import phone2 from '../images/SVG/phone.svg';
import location2 from '../images/SVG/location2.svg';
import fax from '../images/SVG/fax.svg';
import face_icon from '../images/SVG/face_icon.svg';
import insta_icon from '../images/SVG/insta_icon.svg';

import {
    Container,
    Row,
    Col,
    Button,
    FormGroup,
    Input,
    Label,
    NavLink,
    NavItem

} from 'reactstrap';

export default class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.contact = this.contact.bind(this);

        this.state = {
            firstName: '',
            lastName: '',
            phone: '',
            email: '',
            subject: '',
            message: '',
            errors: {}
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }


    contact() {
        let errors = {};
        let error;
        if (!this.state.firstName.trim()){
            errors.firstName = true;
            error = true;
        }
        if (!this.state.lastName.trim()){
            errors.lastName = true;
            error = true;
        }
        if (!this.state.phone.trim()){
            errors.phone = true;
            error = true;
        }

        if (!this.state.email.trim() || this.state.email.indexOf('@') == -1){
            errors.email = true;
            error = true;
        }

        if (!this.state.message.trim()){
            errors.message = true;
            error = true;
        }

        this.setState({
            errors
        })
        if (error){
            return;
        }

        this.props.core.contact(this.state, () => {
            this.setState({
                _done: true
            })
        })


    }

    render() {
        return (
            <div>

                <div className="header_navigaton">

                    <Navigation {...this.props}></Navigation>

                    <Container>
                        <div className="asa">

                            <h2>{this.props.translate('KONTAKT')}</h2>

                            <div className="position">
                                <h3><Link to={"/?lang=" + this.props.lang}>{this.props.translate('POČETNA')}</Link>&nbsp;&nbsp;//&nbsp;&nbsp;{this.props.translate('kontakt')}</h3>
                            </div>
                        </div>
                    </Container>


                </div>
                <div className="head">
                    <h4>{this.props.translate('KONTAKT')}</h4>
                    <p>{this.props.translate('Popunite kontakt formular')}</p>
                </div>
                <div className="kontakt_page" >

                    <Container>
                        <h3>{this.props.translate('KONTAKT')}</h3>
                        <Row>
                            <Col xs="12" xl="8" md="7" lg="7">

                                <Row>
                                    <Col xl="6" md="6" lg="6" xs="12" sm="12">
                                        <FormGroup className={this.state.errors.firstName ? 'required' : ''}>
                                            <Input type="text" name="" id="" placeholder={this.props.translate('Ime')} onChange={(e) => this.setState({ firstName: e.target.value })} value={this.state.firstName} />
                                            <Label for="ime">{this.props.translate('VAŠE IME')}</Label>
                                        </FormGroup>
                                    </Col>
                                    <Col xl="6" md="6" lg="6" xs="12" sm="12">
                                        <FormGroup className={this.state.errors.lastName ? 'required' : ''}>
                                            <Input type="text" name="" id="" placeholder={this.props.translate('Prezime')} onChange={(e) => this.setState({ lastName: e.target.value })} value={this.state.lastName} />
                                            <Label for="prezime">{this.props.translate('VAŠE PREZIME')}</Label>
                                        </FormGroup>

                                    </Col>
                                    <Col xl="6" md="6" lg="6" xs="12" sm="12">
                                        <FormGroup className={this.state.errors.phone ? 'required' : ''}>
                                            <Input type="text" name="" id="" placeholder={this.props.translate('Telefon')} onChange={(e) => this.setState({ phone: e.target.value })} value={this.state.phone} />
                                            <Label for="email">{this.props.translate('VAŠ BROJ TELEFONA')}</Label>
                                        </FormGroup>
                                    </Col>
                                    <Col xl="6" md="6" lg="6" xs="12" sm="12">
                                        <FormGroup className={this.state.errors.email ? 'required' : ''}>
                                            <Input type="email" name="" id="" placeholder={this.props.translate('E-mail')} onChange={(e) => this.setState({ email: e.target.value })} value={this.state.email} />
                                            <Label for="telefon">{this.props.translate('VAŠA E-MAIL ADRESA')}</Label>
                                        </FormGroup>

                                    </Col>

                                </Row>

                                <FormGroup className={this.state.errors.subject ? 'required' : ''}>
                                    <Input type="text" name="" id="naslov" placeholder="Naslov" onChange={(e) => this.setState({ subject: e.target.value })} value={this.state.subject} />
                                    <Label for="naslov">{this.props.translate('SUBJECT')}</Label>
                                </FormGroup>

                                <FormGroup className={this.state.errors.message ? 'required' : ''}>
                                    <Input type="textarea" placeholder={this.props.translate('Vaša poruka')} onChange={(e) => this.setState({ message: e.target.value })} value={this.state.message} />

                                </FormGroup>
                            { !this.state._done ?

                                <Button className="btn" onClick={this.contact}>{this.props.translate('posalji')}</Button>

                                :
                                <p>{this.props.translate('Vaša poruka je uspješno poslata')}</p>
                            }
                            </Col>
                            <Col xl="4" xs="12" md="5" lg="5" sm="12">
                                <div className="kontaktInfo">
                                    <div className="content">
                                        <h6>{this.props.translate('INFORMACIJE')}</h6>
                                        <p>{this.props.translate('Budite slobodni da nas kontaktirate. Odgovorićemo Vam u što kraćem roku.')}</p>

                                        <h2>d.o.o. Sanino</h2>

                                        <NavItem>
                                            <NavLink href="#"><div className="kontakt"><img src={location2} ></img>
                                            </div>{this.props.home.address}</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink href="#"><div className="kontakt"><img src={phone2} ></img>
                                            </div>{this.props.home.phone}</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink href="#"><div className="kontakt"><img src={fax} ></img>
                                            </div>{this.props.home.fax}</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink href="#"><div className="kontakt"><img src={email} ></img>
                                            </div>{this.props.home.email}</NavLink>
                                        </NavItem>

                                        <h3>{this.props.translate('Pratite nas')}<a href={this.props.home.facebook} target="_blank"><Isvg src={face_icon} /> </a><a href={this.props.home.instagram} target="_blank"><Isvg src={insta_icon} /></a></h3>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>

                </div>



                <div className="partneri_about">
                    <Partneri {...this.props}></Partneri>
                </div>

                <div className="mapa">
                    <Map {...this.props}></Map>
                </div>
                <Footer {...this.props}></Footer>
            </div>
        );
    }

}