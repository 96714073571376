import React,{Component} from 'react';
import Isvg from 'react-inlinesvg';
import { Link } from 'react-router-dom';
import news from '../images/news.png';

import moment from 'moment';

import {
    Container,
    Row,
    Col


} from 'reactstrap';


export default class newsItem extends React.Component {
    constructor(props) {
        super(props);

    }

    render() {
        return (
            <div>

                    <div className="vjesti">
                    
                            <Row className="no-gutter">

                                <Col xl={this.props.toPocetna ? "12" : "6" && this.props.toSingle ? "12" : "6"} md="12" lg="12">
                                   <Link to={"/news/"+this.props._id + "?lang="+this.props.lang} > <img src={this.props.image} className="img-fluid imageNews" /></Link>
                                </Col>
                                <Col xl={this.props.toPocetna ? "12" : "6" && this.props.toSingle ? "12" : "6"} md="12" lg="12">
                                    <div className="text">
                                       <Link to={"/news/"+this.props._id + "?lang="+this.props.lang }> <h6>{this.props.title && this.props.title[this.props.lang]}</h6></Link>
                                        <h5>{moment.unix(this.props.time).format('DD.MM.YYYY ')} god. | {moment.unix(this.props.time).format('HH:mm')}</h5>
                                        <p>{this.props.shortDescription && this.props.shortDescription[this.props.lang]}</p>
                                    </div>

                                </Col>

                      
                            </Row>
                         


                    </div>
                </div>

           

        );
    }
}

