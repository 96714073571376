import React, { Component } from 'react';
import './App.css';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Home from './views/home.js';
import About from './views/about.js';
import Partneri from './views/partneri.js';
import Gallery from './views/gallery.js';
import News from './views/newsPage.js';
import SingleNews from './views/singleNews.js';
import GoogleMap from './googleMapScript.js';
import Contact from './views/contact.js';
import Page from './views/page.js';

import { Core } from './core';
import langs from './langs';


class App extends Component {
  constructor(props) {
    super(props);
    this.callback = this.callback.bind(this);
    this.translate = this.translate.bind(this);
    this.changeLanguage = this.changeLanguage.bind(this);
    
    window.googleMapsCallback = this.callback;
    this.core = new Core();

    this.state = {
      lang: 'sr',
      home: {
        aboutus: null,
        certificates: null
      },
      aboutUs: {
        images: []
      },
      partners: {
        partners: []
      },
      gallery: [],
      news: [],
      pages: []
    };
  }
  callback() {
    this.setState({ mapinit: true });
  }

  componentDidMount(){
    this.core.fetchHome((data) => this.setState({home: data}) );
    this.core.fetchAboutUs((data) => this.setState({aboutUs: data}) );
    this.core.fetchPartners((data) => this.setState({partners: data}) );
    this.core.fetchGallery((data) => this.setState({gallery: data}) );
    this.core.fetchNews((data) => this.setState({news: data}) );
    this.core.fetchPages((data) => this.setState({pages: data}) );
    if (window.location.href.indexOf('?lang=') !== -1) {
      let lang = window.location.href.split('?lang=')[1];
      this.setState({
        lang: lang
      })
    }

  }

  translate(text){
    if (langs[this.state.lang][text]) {
      return langs[this.state.lang][text];
    } else {
      return text
    }
  }
  changeLanguage(lang) {
    this.setState({
      lang: lang
    }, () => {
      this.forceUpdate();
    })
  }

  render() {
    console.log(this.state);
    return (
      <div>
 <GoogleMap API_KEY="AIzaSyAaSt58UDZVRmMYe52a3cCPfJaoCaHUJqY" />
        <Router>
          <Switch>
          <Route exact path="/" render={(...renderProps) => (<Home {...renderProps} {...this.state} core={this.core} changeLanguage={this.changeLanguage} translate={this.translate} ></Home>)} />
          <Route exact path="/section1" render={(...renderProps) => (<Home {...renderProps} {...this.state} core={this.core} changeLanguage={this.changeLanguage} translate={this.translate} ></Home>)} />
          <Route exact path="/about" render={(...renderProps) => (<About {...renderProps} {...this.state} core={this.core} changeLanguage={this.changeLanguage} translate={this.translate}></About>)}/>
          <Route exact path="/partners" render={(...renderProps) => (<Partneri {...this.props} {...renderProps} {...this.state} core={this.core} changeLanguage={this.changeLanguage} translate={this.translate}></Partneri>)}/>
          <Route exact path="/contact" render={(...renderProps) => (<Contact {...renderProps} {...this.state} core={this.core} changeLanguage={this.changeLanguage} translate={this.translate}></Contact>)}/>
          <Route exact path="/gallery" render={(...renderProps) => (<Gallery {...renderProps} {...this.state} core={this.core} changeLanguage={this.changeLanguage} translate={this.translate}></Gallery>)}/>
          <Route exact path="/news/:id" render={(...renderProps) => (<SingleNews {...renderProps} {...this.state} core={this.core} changeLanguage={this.changeLanguage} translate={this.translate}></SingleNews>)}/>
          <Route exact path="/news" render={(...renderProps) => (<News {...renderProps} {...this.state} core={this.core} changeLanguage={this.changeLanguage} translate={this.translate}></News>)}/>
          <Route exact path="/pages/:id" render={(...renderProps) => (<Page {...renderProps} {...this.state} core={this.core} changeLanguage={this.changeLanguage} translate={this.translate}></Page>)}/>

          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;
