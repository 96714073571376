import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Navigation from '../containers/navbar.js';
import Kvalitet from '../containers/kvalitet.js';
import Map from '../containers/map';
import Footer from '../containers/footer.js';
import Adidas from '../images/Adidas_logo.png';
import Meindl from '../images/meindl-logo-24.png';
import Haix from '../images/haix1.png';
import Bema from '../images/fdg.png';
import {
    Container,
    Row,
    Col,
    Button,


} from 'reactstrap';

export default class Partneri extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount(){
        window.scrollTo(0,0);
    }

    render() {
        console.log(this.props.partners.partners);
        return (
            <div>
                <div className="header_navigaton">

                    <Navigation {...this.props}></Navigation>

                    <Container>
                        <div className="asa">

                            <h2>{this.props.translate('PARTNERI')}</h2>

                            <div className="position">
                                <h3><Link to={"/?lang="+this.props.lang}>{this.props.translate('POČETNA')}</Link>&nbsp;&nbsp;//&nbsp;&nbsp;{this.props.translate('PARTNERI')}</h3>
                            </div>
                        </div>
                    </Container>


                </div>
                <div className="partneri_pages">
                    <Container>
                        <div className="header">
                            <h4>{this.props.partners.title && this.props.partners.title[this.props.lang]}</h4>
                            <p>{this.props.partners.content && this.props.partners.content[this.props.lang]}</p>
                        </div>
                    {
                        this.props.partners.partners.map((item, idx) => {
                            if (item.content && item.content[this.props.lang]){

                            

                            if (idx % 2 == 0) {
                            return (
                                <div className="content">
                                <Row>
                                    <Col xl="5" lg="5" xs="12" sm="12" md="12">
    
                                        <img src={item.image} className="img-fluid" />
                                    </Col>
                                    <Col xl="7" lg="7" xs="12" sm="12" md="12">
                                        <h2>{item.name && item.name[this.props.lang]}</h2>
                                        <p dangerouslySetInnerHTML={{__html: item.content && item.content[this.props.lang]}}></p>
    
                                    </Col>
                                </Row>
                            </div>
    
                            )
                            }else{
                                return (
                                    <div className="content">
                                    <Row>
                                        <Col xl={{ size: 7, order: 0 }} lg={{ size: 7, order: 0 }} xs={{ size: 12, order: 1 }} sm={{ size: 12, order: 1 }} md={{ size: 12, order: 1 }}>
                                        <h2>{item.name && item.name[this.props.lang]}</h2>
                                        <p dangerouslySetInnerHTML={{__html: item.content && item.content[this.props.lang]}}></p>
        
                                        </Col>
                                        <Col xl={{ size: 5, order: 1 }} lg={{ size: 5, order: 1 }} xs={{ size: 12, order: 0 }} sm={{ size: 12, order: 0 }} md={{ size: 0, order: 0 }}>
                                            <img src={item.image} className="img-fluid meindl" />
                                        </Col>
                                    </Row>
                                </div>
        
                                )
                            }
                        }
                        })
                    }


                    </Container>
                </div>


                <Kvalitet {...this.props}></Kvalitet>
                <div className="mapa">
                    <Map {...this.props}></Map>
                </div>
                <Footer {...this.props}></Footer>
            </div>
        );
    }
}

