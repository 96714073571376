import React, { Component } from 'react';
import Navigation from '../containers/navbar.js';
import Kvalitet from '../containers/kvalitet.js';
import News from '../containers/newsComponent.js';
import Partneri from '../containers/partneriComponent.js';
import Map from '../containers/map.js';
import Footer from '../containers/footer.js';
import { Link } from 'react-router-dom';
import prva from '../images/prva.png';
import sl from '../images/sl.png';
import sl2 from '../images/sl2.png';
import Bacgroundslider1 from '../images/background_slider.png';
import about1 from '../images/about1.png';




import {
    Container,
    Col,
    Row,
    Button,

} from 'reactstrap';


export default class About extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount(){
        window.scrollTo(0,0);
    }

    render() {
        return (
            <div>
                <div className="header_navigaton">

                    <Navigation {...this.props}></Navigation>

                    <Container>
                        <div className="asa">

                            <h2>{this.props.translate('O NAMA')}</h2>

                            <div className="position">
                                <h3><Link to={"/?lang="+this.props.lang}>{this.props.translate('POČETNA')}</Link>&nbsp;&nbsp;//&nbsp;&nbsp;{this.props.translate('o nama')}</h3>
                            </div>
                        </div>
                    </Container>


                </div>
                <div className="lider">
                    <Container>
                        <Row>
                            <Col xl="6" lg="6" md="12" xs="12" sm="12">
                                <img src={this.props.aboutUs && this.props.aboutUs.image} className="img-fluid" />
                            </Col>
                            <Col xl="6" lg="6" md="12" xs="12" sm="12" dangerouslySetInnerHTML={{__html: this.props.aboutUs && this.props.aboutUs.content1 && this.props.aboutUs.content1[this.props.lang]}}>
                            </Col>
                        </Row>
                        <div className="lider2">

                            <Row className="no-gutters">
                                <Col xl={{ size: 6, order: 0 }} lg={{ size: 6, order: 0 }} md={{ size: 12, order: 1 }} xs={{ size: 12, order: 1 }} dangerouslySetInnerHTML={{__html: this.props.aboutUs && this.props.aboutUs.content2 && this.props.aboutUs.content2[this.props.lang]}}>


                                </Col>
                                <Col xl={{ size: 3, order: 1 }} lg={{ size: 3, order: 1 }} xs={{ size: 12, order: 0 }} sm="12" md={{ size: 6, order: 0 }}>
                                    <Col xl="12" md="12" lg="12" className="no-pa">
                                        <img src={this.props.aboutUs && this.props.aboutUs.images && this.props.aboutUs.images[0]} className="img-fluid slika" />
                                    </Col>
                                </Col>

                                <Col xl={{ size: 3, order: 1 }} lg={{ size: 3, order: 1 }} md={{ size: 6, order: 0 }} xs={{ size: 12, order: 0 }}>
                                    <Col xl="12" xs="12" sm="12" md="12" lg="12" className="no-pa">
                                        <img src={this.props.aboutUs && this.props.aboutUs.images && this.props.aboutUs.images[1]} className="img-fluid slika" />
                                    </Col>

                                    <Col xl="12" xs="12" sm="12" md="12" lg="12" className="no-pa">
                                        <img src={this.props.aboutUs && this.props.aboutUs.images && this.props.aboutUs.images[2]} className="img-fluid slika" />
                                    </Col>
                                </Col>


                            </Row>

                        </div>

                        <Row>
                        <Col lg="12">
                                    <h3 className="title-policy">{this.props.aboutUs && this.props.aboutUs.title3 && this.props.aboutUs.title3[this.props.lang]}</h3>
                                </Col>
                                <Col lg="12" dangerouslySetInnerHTML={{__html: this.props.aboutUs && this.props.aboutUs.content3 && this.props.aboutUs.content3[this.props.lang]}}>
                            </Col>


                            </Row>
                    </Container>
                </div>

                <Kvalitet {...this.props}></Kvalitet>

                <div className="novosti">
                    <div className="content">
                        <Container>
                            <div className="text">
                                <Row>
                                    <Col xl="3" xs="6" md="6" lg="6">
                                        <h4>{this.props.translate('Novosti')}</h4>
                                    </Col>
                                    <Col xl="6" xs="6" md="6" lg="6">
                                        <p>{this.props.translate('Novosti o nama i našim uslugama')}</p>
                                    </Col>
                                    <Col xl="3" xs="12" md="12" lg="12">
                                        <Link to={"/news?lang="+this.props.lang}><h5>{this.props.translate('ARHIVA NOVOSTI')}</h5></Link>
                                    </Col>
                                </Row>
                            </div>

                        </Container>
                    </div>
                    <div className="boxNews">
                        <News {...this.props}></News>
                    </div>

                </div>

                <div className="partneri_about">
                    <Partneri {...this.props}></Partneri>
                </div>

                <div className="mapa">
                    <Map {...this.props}></Map>
                </div>
                <Footer {...this.props}></Footer>

            </div>
        );
    }
}