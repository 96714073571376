const langs  = {
    "en": {
        "Vaša poruka je uspješno poslata": "Your message was successfully sent",
        "Pratite nas": "Follow us",
        "IZDVAJAMO": "HIGHLIGHTS",
        "Pored \"Adidasa\", \"Sanino\" veoma uspješno sarađuje sa poznatim svjetskim proizvođačima obuće": "In addition to \"Adidas\", \"Sanino\" cooperates very well with well-known footwear manufacturers in the world",
        "DETALJNIJE": "MORE INFO",
        "Novosti": "News",
        "Novosti o nama i našim uslugama": "News about us and our services",
        "ARHIVA NOVOSTI": "NEWS ARCHIVE",
        "FOTO GALERIJA": "PHOTO GALLERY",
        "Kompletna galerija": "Complete gallery",
        "POČETNA": "HOME",
        "O NAMA": "ABOUT US",
        "PARTNERI": "PARTNERS",
        "GALERIJA": "GALLERY",
        "NOVOSTI": "NEWS",
        "KONTAKT": "CONTACT",
        "NAŠI PARTNERI": "OUR PARTNERS",
        "NAVIGACIJA": "NAVIGATION",
        "Početna": "Home",
        "O nama": "About Us",
        "Partneri": "Partners",
        "Galerija": "Gallery",
        "Kontakt": "Contact",
        "novosti": "News",
        "NOVOSTI O NAMA": "News about us",
        "KVALITETOM DO POVJERENJA": "QUALITY TO TRUST",
        "o nama": "About us",
        "GALERIJA POSLOVNICE": "BRANCH GALLERY",
        "Galerija naše poslovnice u Derventi": "Gallery of our Derventa branch",
        "kontakt": "contact",
        "Popunite kontakt formular": "Fill out the contact form",
        "Ime": "Name",
        "VAŠE IME": "YOUR NAME",
        "Prezime": "Surname",
        "VAŠE PREZIME": "YOUR SURNAME",
        "Telefon": "Telephone",
        "VAŠ BROJ TELEFONA": "YOUR PHONE NUMBER",
        "E-mail": "E-mail",
        "VAŠA E-MAIL ADRESA": "YOUR E-MAIL ADDRESS",
        "SUBJECT": "SUBJECT",
        "Vaša poruka": "Your message",
        "posalji": "send",
        "INFORMACIJE": "INFORMATION",
        "Budite slobodni da nas kontaktirate. Odgovorićemo Vam u što kraćem roku.": "Feel free to contact us. We will reply as soon as possible."
    },
    "sr": {
        "Pratite nas": "Pratite nas",
        "IZDVAJAMO": "IZDVAJAMO",
        "Pored \"Adidasa\", \"Sanino\" veoma uspješno sarađuje sa poznatim svjetskim proizvođačima obuće": "Pored \"Adidasa\", \"Sanino\" veoma uspješno sarađuje sa poznatim svjetskim proizvođačima obuće",
        "DETALJNIJE": "DETALJNIJE",
        "Novosti": "Novosti",
        "Novosti o nama i našim uslugama": "Novosti o nama i našim uslugama",
        "ARHIVA NOVOSTI": "ARHIVA NOVOSTI",
        "FOTO GALERIJA": "FOTO GALERIJA",
        "Kompletna galerija": "Kompletna galerija",
        "POČETNA": "POČETNA",
        "O NAMA": "O NAMA",
        "PARTNERI": "PARTNERI",
        "GALERIJA": "GALERIJA",
        "NOVOSTI": "NOVOSTI",
        "KONTAKT": "KONTAKT",
        "NAŠI PARTNERI": "NAŠI PARTNERI",
        "NAVIGACIJA": "NAVIGACIJA",
        "Početna": "Početna",
        "O nama": "O nama",
        "Partneri": "Partneri",
        "Galerija": "Galerija",
        "Kontakt": "Kontakt",
        "novosti": "novosti",
        "NOVOSTI O NAMA": "NOVOSTI O NAMA",
        "KVALITETOM DO POVJERENJA": "KVALITETOM DO POVJERENJA",
        "o nama": "o nama",
        "GALERIJA POSLOVNICE": "GALERIJA POSLOVNICE",
        "Galerija naše poslovnice u Derventi": "Galerija naše poslovnice u Derventi",
        "kontakt": "kontakt",
        "Popunite kontakt formular": "Popunite kontakt formular",
        "Ime": "Ime",
        "VAŠE IME": "VAŠE IME",
        "Prezime": "Prezime",
        "VAŠE PREZIME": "VAŠE PREZIME",
        "Telefon": "Telefon",
        "VAŠ BROJ TELEFONA": "VAŠ BROJ TELEFONA",
        "E-mail": "E-mail",
        "VAŠA E-MAIL ADRESA": "VAŠA E-MAIL ADRESA",
        "SUBJECT": "SUBJECT",
        "Vaša poruka": "Vaša poruka",
        "posalji": "posalji",
        "INFORMACIJE": "INFORMACIJE",
        "Budite slobodni da nas kontaktirate. Odgovorićemo Vam u što kraćem roku.": "Budite slobodni da nas kontaktirate. Odgovorićemo Vam u što kraćem roku."
    },
    "de": {
        "Vaša poruka je uspješno poslata": "Ihre Nachricht wurde erfolgreich versandt",
        "Pratite nas": "Folge uns",
        "IZDVAJAMO": "HIGHLIGHTS",
        "Pored \"Adidasa\", \"Sanino\" veoma uspješno sarađuje sa poznatim svjetskim proizvođačima obuće": "Neben \"Adidas\", \"Sanino\" sehr gut mit bekannten Schuhherstellern auf der ganzen Welt zusammen",
        "DETALJNIJE": "MEHR INFO",
        "Novosti": "Nachrichten",
        "Novosti o nama i našim uslugama": "Neuigkeiten über uns und unsere Dienstleistungen",
        "ARHIVA NOVOSTI": "NEWS-ARCHIV",
        "FOTO GALERIJA": "FOTOGALLERIE",
        "Kompletna galerija": "Komplette Galerie",
        "POČETNA": "STARTSEITE",
        "O NAMA": "ÜBER UNS",
        "PARTNERI": "PARTNER",
        "GALERIJA": "GALERIE",
        "NOVOSTI": "NACHRICHTEN",
        "KONTAKT": "KONTAKT",
        "NAŠI PARTNERI": "UNSERE PARTNER",
        "NAVIGACIJA": "NAVIGATION",
        "Početna": "Startseite",
        "O nama": "Über uns",
        "Partneri": "Partner",
        "Galerija": "Galerie",
        "Kontakt": "Kontakt",
        "novosti": "Nachrichten",
        "NOVOSTI O NAMA": "Neuigkeiten über uns",
        "KVALITETOM DO POVJERENJA": "QUALITÄT VERTRAUEN",
        "o nama": "Über uns",
        "GALERIJA POSLOVNICE": "ZWEIG-GALERIE",
        "Galerija naše poslovnice u Derventi": "Galerie unserer Derventa-Filiale",
        "kontakt": "Kontakt",
        "Popunite kontakt formular": "Füllen Sie das Kontaktformular aus",
        "Ime": "Name",
        "VAŠE IME": "DEIN NAME",
        "Prezime": "Nachname",
        "VAŠE PREZIME": "DEIN FAMILIENNAME",
        "Telefon": "Telefon",
        "VAŠ BROJ TELEFONA": "DEINE TELEFONNUMMER",
        "E-mail": "E-mail",
        "VAŠA E-MAIL ADRESA": "DEINE EMAILADRESSE",
        "SUBJECT": "GEGENSTAND",
        "Vaša poruka": "Deine Nachricht",
        "posalji": "senden",
        "INFORMACIJE": "INFORMATION",
        "Budite slobodni da nas kontaktirate. Odgovorićemo Vam u što kraćem roku.": "Fühlen Sie sich frei uns zu kontaktieren. Wir werden so schnell wie möglich antworten."
    },
};
export default langs;