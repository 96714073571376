import React, {Component} from 'react';
import news from '../images/news.png';

import NewsItem from './newsItem.js';

import {
    Container,
     Row, 
     Col
} from 'reactstrap';

const articles = [
    {
        _id: '1',
        allias: 'sdsd',
        title: 'Sanino Na 4.Sajmu Privrede I Turizma 2009',
        date: '05.04.2019. god.  | 14:28h',
        description: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur.',
        image: news
    },
    {
        _id: '2',
        allias: 'sdsd',
        title: 'Sanino Na 4.Sajmu Privrede I Turizma 2009',
        date: '05.04.2019. god.  | 14:28h',
        description: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur.',
        image: news
    },
    {
        _id: '3',
        allias: 'sdsd',
        title: 'Sanino Na 4.Sajmu Privrede I Turizma 2009',
        date: '05.04.2019. god.  | 14:28h',
        description: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur.',
        image: news
    },
    {
        _id: '4',
        allias: 'sdsd',
        title: 'Sanino Na 4.Sajmu Privrede I Turizma 2009',
        date: '05.04.2019. god.  | 14:28h',
        description: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur.',
        image: news
    },
  
  
];



export default class NewsComponent extends React.Component{
    constructor(props){
        super(props);
    }
    render(){
        const toPocetna = this.props.pocetna;
        const toSingle = this.props.singleNews;
        return(
            <Container>
              
                <Row>
                    {this.props.news.map((item) => {
                        return (
                            <Col xl={this.props.singleNews ? "12" : "6"} lg="6" md="6" xs = "12" sm="12" key={item._id}>
                           
                                <NewsItem {...this.props} {...item} toPocetna = {toPocetna} toSingle = {toSingle} ></NewsItem>
                             
                            </Col>

                        )
                    })}

                </Row>
            </Container>

        );
    }
}


 