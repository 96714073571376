import React from 'react';
import Isvg from 'react-inlinesvg';
import { Link } from 'react-router-dom';
import Map from '../containers/map.js';
import News from '../containers/newsComponent.js';
import Partneri from '../containers/partneriComponent.js';
import Footer from '../containers/footer.js';
import Navigation from '../containers/navbar';
import Kvalitet from '../containers/kvalitet.js';



import slide1 from '../images/img/haix.png';
import slide2 from '../images/img/meindl.png';
import slide3 from '../images/slide3.png';
import slide4 from '../images/img/lagero.png';
import slide5 from '../images/img/paulslider.png';
import paulGreen from '../images/img/paulgreen.png';

import location from '../images/SVG/location.svg';
import face_icon from '../images/SVG/face_icon.svg';
import insta_icon from '../images/SVG/insta_icon.svg';
import prva from '../images/prva.png';
import sl from '../images/sl.png';
import sl2 from '../images/sl2.png';
import adidas from '../images/adidas.png'
import meindl from '../images/meindl.png';
import haix from '../images/haix.png';



import adidass from '../images/section3/adidass.png';
import bema from '../images/section3/bema.png';
import lageroLogo from '../images/img/lagerologo.png';

import slika1 from '../images/galerija/slika1.png';
import slika2 from '../images/galerija/slika2.png';
import slika3 from '../images/galerija/slika3.png';
import slika4 from '../images/galerija/slika4.png';
import slika5 from '../images/galerija/slika5.png';
import slika6 from '../images/galerija/slika6.png';
import lupa from '../images/gallery/lupa.png';
import plus from '../images/plus.png';





import {
    Container,
    Row,
    Col,
    Button,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,



} from 'reactstrap';


const items = [
    {
        src: slide3,
        caption: "ADIDAS KOPAČKE",
        captionHeader: "GORNJI DIJELOVI",
        button: <Link to="/about"><Button>Detaljnje</Button></Link>,
        logo: adidas,
        description: "ŠIVENJE<br/> GORNJIH DIJELOVA KOPAČKI",
        plusImg: plus


    },
    {
        src: slide2,
        caption2: "GORNJI DIJELOVI",
        captionHeader2: "OBUCA MEINDL",
        button2: <Link to="/about"><Button>Detaljnje</Button></Link>,
        logo2: meindl,
        description2: 'MATERIJAL VRHUNSKOG KVALITETA',
        plusImg2: plus
    },
    {
        src: slide1,
        caption2: "ZA MODELE HAIX",
        captionHeader2: "GORNJI DIJELOVI",
        button2: <Link to="/about"><Button>Detaljnje</Button></Link>,
        logo2: haix,
        description2: "ZAŠTITNA I VODOOTPORNA OBUĆA",
        plusImg2: plus
    },
    {
        src: slide4,
        caption3: "OBUĆE LAGERO",
        captionHeader3: "GORNJI DIJELOVI",
        button3: <Link to="/about"><Button>Detaljnje</Button></Link>,
        logo3: lageroLogo,
        description3: "MATERIJAL VRHUNSKOG KVALITETA",
        plusImg3: plus
    },
    {
        src: slide5,
        caption: "OBUĆA PAUL GREEN",
        captionHeader: "GORNJI DIJELOVI",
        button: <Link to="/about"><Button>Detaljnje</Button></Link>,
        logo: paulGreen,
        description: "ŠIVENJE<br/> GORNJIH DIJELOVA KOPAČKI",
        plusImg: plus


    },

];

const pictures = [
    {
        src: slika1
    },
    {
        src: slika2
    },
    {
        src: slika3
    },
    {
        src: slika4
    },
    {
        src: slika5
    },
    {
        src: slika6
    }
];

export default class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            progress: 0
        };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.state = {
            activeIndex: 0
        };

        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);
    }


    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async componentDidUpdate(prevProps) {
        if (prevProps[0].location.pathname !== this.props[0].location.pathname) {
            let currScroll = window.pageYOffset;
            let offsetTop = 0;

            if (this.props[0].location.pathname === '/section1' && this.section1) {
                offsetTop = this.section1.offsetTop;
            }


            if (currScroll > offsetTop) {
                for (let i = currScroll; i >= offsetTop; i -= 20) {
                    window.scrollTo(0, i);
                    await this.sleep((2000 / offsetTop / 20));
                }

            } else {
                for (let i = currScroll; i < offsetTop; i += 20) {
                    window.scrollTo(0, i);
                    await this.sleep((2000 / offsetTop / 20));
                }
            }
        }
    }




    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        window.scrollTo(0, 0);



    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    onExiting() {
        this.animating = true;
    }

    onExited() {
        this.animating = false;
    }

    next() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === items.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    }

    previous() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? items.length - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    }

    goToIndex(newIndex) {
        if (this.animating) return;
        this.setState({ activeIndex: newIndex });
    }


    render() {


        const { activeIndex } = this.state;

        const slides = items.map((item) => {

            return (

                <CarouselItem className="bg"
                    onExiting={this.onExiting}
                    onExited={this.onExited}
                    key={item.src} >
                    <Container>

                        <img src={item.src} className="img-fluid" alt={item.altText} />

                        {/*slider 1*/}
                        <div className="caption1"><h6>{item.captionHeader}</h6> <h2>{item.caption}</h2> {item.button} </div>
                        <div className="plus"><img src={item.plusImg} className="img-fluid" /></div>
                        <div className="left_side"><img src={item.logo} className="img-fluid logo" /> </div>
                        <div className="description"><p dangerouslySetInnerHTML={{ __html: item.description }}></p></div>




                        {/*slider 2*/}
                        <div className="caption2"><h6>{item.captionHeader2}</h6> <h2>{item.caption2}</h2>{item.button2} </div>
                        <div className="plus2"><img src={item.plusImg2} className="img-fluid" /></div>
                        <div className="left_side"><img src={item.logo2} className="img-fluid" /></div>
                        <div className="description2"><p>{item.description2}</p></div>

                        {/*slider 3*/}
                        <div className="caption3"><h6>{item.captionHeader3}</h6> <h2>{item.caption3}</h2> {item.button3} </div>
                        <div className="plus3"><img src={item.plusImg3} className="img-fluid" /></div>
                        <div className="right_side"><img src={item.logo3} className="img-fluid" /></div>
                        <div className="description3"><p>{item.description3}</p></div>
                    </Container>
                </CarouselItem>


            )

        });
        const slides2 = pictures.map((item) => {
            return (
                <CarouselItem onExiting={this.onExiting} onExited={this.onExited} key={item.src}>
                    <img src={item.src} className="img-fluid" />
                </CarouselItem>
            );
        });
        return (
            <div>
                <div className="proba">
                    <div className="slider">
                        <div className="line"></div>
                        <div className="subline inc"></div>
                        <div className="subline dec"></div>
                    </div>
                    <Navigation {...this.props}></Navigation>
                    <div className="wrapper">
                        <Container fluid>
                            <Row>
                                <Carousel className="bg" activeIndex={activeIndex} next={this.next} previous={this.previous}>

                                    {slides}
                                    <div className="arrows">
                                        <CarouselControl direction="prev" directionText="" onClickHandler={this.previous} />
                                        <CarouselControl direction="next" directionText="" onClickHandler={this.next} />
                                    </div>
                                    <Container>

                                        <div className="left_slider">
                                            <h6><Isvg src={location} /> Dubička bb, Derventa</h6>
                                        </div>
                                        <div className="right_slider">
                                            <h6>{this.props.translate('Pratite nas')}  </h6>
                                            <Isvg src={face_icon} /><Isvg src={insta_icon} />
                                        </div>

                                    </Container>
                                </Carousel>

                            </Row>
                        </Container>
                        <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
                    </div>

                </div>
                <div className="middle d-flex justify-content-center">
                    <Link to="/section1"><Button></Button></Link>
                </div>





                <div className="section-1" ref={(node) => this.section1 = node}>
                    <Container>
                        {this.props.home.aboutus ?
                            <Row className="no-gutters">

                                <Col xl="3" xs="12" sm="12" md="6" lg="3" >
                                    <Col xl="12" md="12" lg="12" className="no-pa no-pa-left">
                                        <img src={this.props.home.aboutus.image && this.props.home.aboutus.image[0]} className="img-fluid slika" />
                                    </Col>
                                </Col>

                                <Col xl="3" md="6" lg="3">
                                    <Col xl="12" xs="12" sm="12" md="12" lg="12" className="no-pa">
                                        <img src={this.props.home.aboutus.image && this.props.home.aboutus.image[1]} className="img-fluid slika" />
                                    </Col>

                                    <Col xl="12" xs="12" sm="12" md="12" lg="12" className="no-pa">
                                        <img src={this.props.home.aboutus.image && this.props.home.aboutus.image[2]} className="img-fluid" />
                                    </Col>
                                </Col>

                                <Col xl="6" md="12" lg="6">
                                    <div className="text">
                                        <h4>{this.props.home.aboutus.title && this.props.home.aboutus.title[this.props.lang]}</h4>
                                        <p>{this.props.home.aboutus.content && this.props.home.aboutus.content[this.props.lang]}</p>

                                        <Link to="/about"> <Button className="btn">{this.props.translate('DETALJNIJE')}</Button></Link>
                                    </div>
                                </Col>
                            </Row>

                            :
                            null
                        }
                    </Container>
                </div>

                <Kvalitet {...this.props}></Kvalitet>

                {/*<div className="section-3">

                    <div className="content">
                        <Container>
                            <div className="text">
                                <Row>
                                    <Col sm="4" xs="12" xl="3" md="6" lg="3">
                                        <h4>{this.props.translate('IZDVAJAMO')}</h4>
                                    </Col>
                                    <Col sm="7" xs="12" xl="6" md="6" lg="6">
                                        <p>{this.props.translate('Pored "Adidasa", "Sanino" veoma uspješno sarađuje sa poznatim svjetskim proizvođačima obuće')}</p>
                                    </Col>

                                </Row>
                            </div>
                            <Row>
                                <Col xl="4" lg="4" xs="12" md="4" sm="12">
                                    <div className="selected">
                                        <img src={this.props.pages[0] && this.props.pages[0].image} className="img-fluid" />
                                        <div className="aboutSelected">
                                            <h5>{this.props.pages[0] && this.props.pages[0].title && this.props.pages[0].title[this.props.lang]} </h5>
                                            <p>{this.props.pages[0] && this.props.pages[0].subtitle && this.props.pages[0].subtitle[this.props.lang]}</p>
                                            <Link to={'/pages/' + (this.props.pages[0] && this.props.pages[0]._id) + "?lang="+this.props.lang}><Button><h6>{this.props.translate('DETALJNIJE')}</h6></Button></Link>


                                        </div>
                                    </div>

                                </Col>
                                <Col xl="4" lg="4" xs="12" md="4" sm="12">
                                    <div className="selected">
                                        <img src={this.props.pages[1] && this.props.pages[1].image} className="img-fluid" />
                                        <div className="aboutSelected">
                                            <h5>{this.props.pages[1] && this.props.pages[1].title && this.props.pages[1].title[this.props.lang]} </h5>
                                            <p>{this.props.pages[1] && this.props.pages[1].subtitle && this.props.pages[1].subtitle[this.props.lang]}</p>
                                            <Link to={'/pages/' + (this.props.pages[1] && this.props.pages[1]._id) + "?lang="+this.props.lang}><Button><h6>{this.props.translate('DETALJNIJE')}</h6></Button></Link>


                                        </div>
                                    </div>
                                </Col>
                                <Col xl="4" lg="4" xs="12" md="4" sm="12">
                                    <Row>
                                        <Col xl="12" xs="12" md="12" sm="12">
                                            <div className="selected">
                                                <Link to={'/pages/' + (this.props.pages[2] && this.props.pages[2]._id) + "?lang="+this.props.lang}>
                                                    <img src={this.props.pages[2] && this.props.pages[2].image} className="img-fluid mendol" />
                                                </Link>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl="12" lg="12" xs="12" md="12" sm="12">
                                            <div className="selected">
                                            <Link to={'/pages/'+ (this.props.pages[3] && this.props.pages[3]._id) + "?lang="+this.props.lang }>
                                                <img src={this.props.pages[3] && this.props.pages[3].image} className="img-fluid" />
                                                </Link>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
                    */}
                <div className="section-1 section-3" >
                    <Container>
                        {this.props.home.certificates ?
                            <Row className="no-gutters">

                                <Col xl="4" xs="12" sm="12" md="6" lg="6" >
                                    <Col xl="12" md="12" lg="12" className="no-pa no-pa-left">
                                        <a href={this.props.home.certificates.image && this.props.home.certificates.image[this.props.lang == 'sr' ? 0 : this.props.lang == 'en' ? 1 : 2]} target="_blank">
                                            <img src={this.props.home.certificates.image && this.props.home.certificates.image[this.props.lang == 'sr' ? 0 : this.props.lang == 'en' ? 1 : 2]} className="img-fluid slika" />
                                        </a>
                                    </Col>
                                </Col>

                                <Col xl="8" md="12" lg="6">
                                    <div className="text">
                                        <h4>{this.props.home.certificates.name && this.props.home.certificates.name[this.props.lang]}</h4>
                                        <p>{this.props.home.certificates.content && this.props.home.certificates.content[this.props.lang]}</p>

                                    </div>
                                </Col>
                            </Row>

                            :
                            null
                        }
                    </Container>
                </div>




                <div className="novosti">

                    <div className="content">
                        <Container>
                            <div className="text">
                                <Row>
                                    <Col xl="3" xs="6" md="6" lg="6" sm="6">
                                        <h4>{this.props.translate('Novosti')}</h4>
                                    </Col>
                                    <Col xl="6" xs="6" md="6" lg="6" sm="6">
                                        <p>{this.props.translate('Novosti o nama i našim uslugama')}</p>
                                    </Col>
                                    <Col xl="3" xs="12" md="12" lg="12" sm="6">
                                        <Link to="/news"><h5>{this.props.translate('ARHIVA NOVOSTI')}</h5></Link>
                                    </Col>
                                </Row>
                            </div>

                        </Container>
                    </div>
                    <div className="boxNews">
                        <News {...this.props}></News>
                    </div>

                </div>




                <div className="foto_galerija">
                    <Container>
                        <h4>{this.props.translate('FOTO GALERIJA')}</h4>
                        <Row>
                            <Col xl="5" lg="5" md="4" xs="12" sm="12">
                                <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 0 })}>
                                    <img src={slika1} className="img-fluid slika" />
                                    <div className="overlay">
                                        <img src={lupa} />
                                    </div>
                                </div>
                            </Col>
                            <Col xl="7" lg="7" md="8" xs="12" sm="12">
                                <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 1 })}>
                                    <img src={slika3} className="img-fluid slika" />
                                    <div className="overlay">
                                        <img src={lupa} />
                                    </div>
                                </div>
                            </Col>


                        </Row>
                        <Row className="no-gutter">
                            <Col xl="5" lg="5" md="6" xs="12" sm="12">
                                <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 2 })}>
                                    <img src={slika2} className="img-fluid slika" />
                                    <div className="overlay">
                                        <img src={lupa} />
                                    </div>
                                </div>
                            </Col>

                            <Col xl="7" lg="7" md="6" xs="12" sm="12">
                                <div className="asa">

                                    <Col xl="7" lg="7" md="7" xs="12" sm="12">
                                        <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 3 })}>
                                            <img src={slika4} className="img-fluid slika" />
                                            <div className="overlay">
                                                <img src={lupa} />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xl="5" lg="5" md="5" xs="12" sm="12">
                                        <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 4 })}>
                                            <img src={slika5} className="img-fluid slika" />
                                            <div className="overlay">
                                                <img src={lupa} />
                                            </div>
                                        </div>

                                        <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 5 })}>
                                            <img src={slika6} className="img-fluid slika1" />
                                            <div className="overlay">
                                                <img src={lupa} />
                                            </div>
                                        </div>

                                    </Col>
                                </div>
                            </Col>
                            {this.state.opened ?
                                <div className="lightbox">
                                    <Container>
                                        <Row>
                                            <i className="fa fa-times" aria-hidden="true" onClick={() => this.setState({ opened: false })}></i>

                                            <Carousel className="bg"
                                                activeIndex={activeIndex}
                                                next={this.next}
                                                previous={this.previous}
                                            >
                                                <CarouselIndicators items={pictures} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
                                                {slides2}
                                                <i className="fa fa-angle-left" onClick={this.previous}></i>
                                                <i className="fa fa-angle-right" onClick={this.next}></i>
                                            </Carousel>
                                        </Row>
                                    </Container>
                                </div> : null
                            }
                        </Row>
                    </Container>
                    <Row className="d-flex justify-content-center">
                        <Link to={"/gallery?lang=" + this.props.lang}><Button><span>{this.props.translate('Kompletna galerija')}</span></Button></Link>
                    </Row>
                </div>

                <div className="partneri_home">
                    <Partneri {...this.props}></Partneri>
                </div>



                <div className="mapa">
                    <Map {...this.props}></Map>
                </div>
                <div className="footer_home">
                    <Footer {...this.props}></Footer>
                </div>
            </div>
        );
    }
}

