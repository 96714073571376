import React from 'react';
import { Link } from 'react-router-dom';
import Isvg from 'react-inlinesvg';
import {
    Container,
    Row,
    Col,
    NavItem,
    NavLink
} from 'reactstrap';
import email from '../images/SVG/email.svg';
import phone2 from '../images/SVG/phone.svg';
import location2 from '../images/SVG/location2.svg';
import logo from '../images/logofooter.svg';
import fax from '../images/SVG/fax.svg';
import facebook from '../images/SVG/facebook_yellow.svg';
import instagram from '../images/SVG/insta_icon_yellow.svg';


export default class Footer extends React.Component {
    render() {
        return (
            <div>
              <div className="footer">
                <Container>
                    <Isvg src={logo} className="logo"/>
                    <Row className="no-gutters">
                        <Col xl="4" lg="4"  xs="12" md="4"> 
                        <h4>{this.props.home.footerText && this.props.home.footerText[this.props.lang]}</h4>

                        <h6>{this.props.translate('Pratite nas')}<a href={this.props.home.facebook} target="_blank"><Isvg src={facebook}/> </a><a href={this.props.home.instagram} target="_blank"><Isvg src={instagram}/></a></h6>
                        
                        </Col>
                      
                        <Col xl="4" md="4" lg="4" xs="6" className="offset-xl-1" className="offset-lg-1" className="offset-md-1">
                        <h5>{this.props.translate('NAVIGACIJA')}</h5>
                        <Row>
                        <Col xl="6" lg="6" xs="6" md="6">
                        <NavItem>
                            <Link to={"/?lang="+this.props.lang}>{this.props.translate('Početna')}</Link>
                        </NavItem>
                        <NavItem>
                            <Link to={"/about?lang="+this.props.lang}>{this.props.translate('O nama')}</Link>
                        </NavItem>
                        <NavItem>
                            <Link to={"/partners?lang="+this.props.lang}>{this.props.translate('Partneri')}</Link>
                        </NavItem>
                        </Col>

                        <Col xl="6" lg="6" xs="6" md="6">
                        <NavItem>
                            <Link to={"/gallery?lang="+this.props.lang}>{this.props.translate('Galerija')}</Link>
                        </NavItem>
                        <NavItem>
                            <Link to={"/news?lang="+this.props.lang}>{this.props.translate('Novosti')}</Link>
                        </NavItem>
                        <NavItem>
                            <Link to={"/contact?lang="+this.props.lang}>{this.props.translate('Kontakt')}</Link>
                        </NavItem>
                        </Col>
                        </Row>
                        </Col>
                      
                        <Col xl="3" lg="3" xs="12" md="3">
                        <div className="contactData">
                        <h5>{this.props.translate('KONTAKT')}</h5>
                        <NavItem>
                                    <NavLink href="#"><div className="kontakt"><img src={location2} ></img>
                                    </div>{this.props.home.address}</NavLink>
                         </NavItem>
                         <NavItem>
                                    <NavLink href="#"><div className="kontakt"><img src={phone2} ></img>
                                    </div>{this.props.home.phone}</NavLink>
                         </NavItem>
                         <NavItem>
                                    <NavLink href="#"><div className="kontakt"><img src={fax} ></img>
                                    </div>{this.props.home.fax}</NavLink>
                         </NavItem>
                         <NavItem>
                                    <NavLink href="#"><div className="kontakt"><img src={email} ></img>
                                    </div>{this.props.home.email}</NavLink>
                         </NavItem>
                         </div>
                        </Col>
                    </Row>
                </Container>
    
              </div>
              <div className="copyright">
                    <Container>
                        <Row>
                            <Col xl="5" lg="6" md="8" sm="12" xs="12">
                                <p>Copyright © Sanino d.o.o.  - 2019. All Rights Reserved.</p>
                            </Col>
                            <Col xl="3" lg="3" md="4" sm="12" xs="12" className="offset-xl-4 offset-lg-3 d-flex justify-content-xl-end justify-content-sm-start">
                                <p>Created by <span><a href="https://www.novamedia.agency/" target="_blank">NOVA media</a></span></p>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>

        )
    }
};