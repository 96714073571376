import React from 'react';
import {Link} from 'react-router-dom';
import Navigation from '../containers/navbar.js';
import Partneri from '../containers/partneriComponent.js';
import Map from '../containers/map.js';
import Footer from '../containers/footer.js';
import News from '../containers/newsComponent.js';
import SingleNewsImage from '../images/news.png';
import moment from 'moment';
import {
    Row,
    Col,
    Container,

} from 'reactstrap';


export default class SingleNews extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount(){
        window.scrollTo(0,0);
    }

    render() {

        let data = {};

        for(let i=0;i<this.props.news.length;i++){
            if (this.props.news[i]._id == this.props[0].match.params.id){
                data = this.props.news[i];
                break;
            }
        }

        const singleNews = 'singleNews';
        return (
            <div>
                <div className="header_navigaton">

                    <Navigation {...this.props}></Navigation>

                    <Container>
                        <div className="asa">

                        <h2>{this.props.translate('NOVOSTI')}</h2>

                        <div className="position">
                            <h3><Link to={"/?lang="+this.props.lang}>{this.props.translate('POČETNA')}</Link>&nbsp;&nbsp;//&nbsp;&nbsp;{this.props.translate('novosti')}</h3>
                        </div>
                        </div>
                    </Container>
                   

                </div>

                <Container>
             
             
              
                   

                
                

                    <Row>
                        <Col xl="8"  xs="12" lg="12" md="12" sm="12" className="no-gutters">
                        <div className="textSingle">
                        <h6>{data.title && data.title[this.props.lang]}</h6>
                        <h5>{moment.unix(data.time).format('DD.MM.YYYY ')} god. | {moment.unix(data.time).format('HH:mm')}</h5>
                        <p>{data.shortDescription && data.shortDescription[this.props.lang]}</p>
                    
                    </div>
                            <Col xl="12" lg="12" xs="12" sm="12" md="12">
                                <img src={data.image} className="img-fluid" />
                            </Col>
                            <Col xl="12" lg="12" xs="12" sm="12" md="12" >
                                <br></br>
                                <p dangerouslySetInnerHTML={{__html: data.content && data.content[this.props.lang]}}>
                </p>
                            </Col>
                        </Col>
                        <Col xl="4" xs="12" sm="12" lg="12" md="12">
                        <div className="singleHeader_component">
                        <h6>{this.props.translate('NOVOSTI')}</h6>
                    </div>

                            <div className="singleN-wrapper">
                                <News {...this.props} singleNews={singleNews}></News>
                            </div>
                        </Col>
                    </Row>
                </Container>


                <div className="partneri_about">
                    <Partneri {...this.props}></Partneri>
                </div>

                <div className="mapa">
                    <Map {...this.props}></Map>
                </div>
                <Footer {...this.props}></Footer>
            </div>
        );
    }
}