import socketIO from 'socket.io-client';

const API_KEY = '5d91faf9e92bc847c617d4d6';

export class Core {
    constructor(props) {
        this.socket = socketIO('https://socket.hugemedia.online');

    }

    fetchHome(callback) {
        this.socket.emit('apiCall', {
            apiKey: API_KEY,
            apiName: 'fetchHome',
            query: {}
        });

        this.socket.once('fetchHome', callback);
    }
    fetchAboutUs(callback) {
        this.socket.emit('apiCall', {
            apiKey: API_KEY,
            apiName: 'fetchAboutUs',
            query: {}
        });

        this.socket.once('fetchAboutUs', callback);
    }
    fetchPartners(callback) {
        this.socket.emit('apiCall', {
            apiKey: API_KEY,
            apiName: 'fetchPartners',
            query: {}
        });

        this.socket.once('fetchPartners', callback);
    }
    fetchGallery(callback) {
        this.socket.emit('apiCall', {
            apiKey: API_KEY,
            apiName: 'fetchGallery',
            query: {}
        });

        this.socket.once('fetchGallery', callback);
    }

    fetchPages(callback) {
        this.socket.emit('apiCall', {
            apiKey: API_KEY,
            apiName: 'fetchPages',
            query: {}
        });

        this.socket.once('fetchPages', callback);
    }


    fetchNews(callback) {
        this.socket.emit('apiCall', {
            apiKey: API_KEY,
            apiName: 'fetchNews',
            query: {},
            sort: {_id: -1}
        });

        this.socket.once('fetchNews', callback);
    }
    contact(data, callback) {
        this.socket.emit('apiCall', {
            apiKey: API_KEY,
            apiName: 'contact',
            query: {},
            data: data
        });

        this.socket.once('contact', callback);
    }
}