import React, { Component } from 'react';

import medalja from '../images/SVG/medalja.svg';
import people from '../images/SVG/people.svg';
import fast from '../images/SVG/fast.svg';

import { Container, Row, Col } from 'reactstrap';





export default class Kvalitet extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        console.log(this.props.home);
        if (!this.props.home.info)
            return null;
        
        return null;

        return (
            <div>

                <div className="section-2">
                    <Container>
                        <h4>{this.props.translate('KVALITETOM DO POVJERENJA')}</h4>
                        <Row>

                            <Col sm="12" xs="12" md="6" xl="4" lg="4" >
                                <div className="box color">
                                    <img src={this.props.home.info[0].image} className="img-fluid" />
                                    <h2>{this.props.home.info[0].title && this.props.home.info[0].title[this.props.lang]}</h2>
                                    <p>{this.props.home.info[0].content && this.props.home.info[0].content[this.props.lang]}</p>
                                </div>
                            </Col>
                            <Col sm="12" xs="12" md="6" xl="4" lg="4" >
                                <div className="box">
                                    <img src={this.props.home.info[1].image} className="img-fluid" />
                                    <h2>{this.props.home.info[1].title && this.props.home.info[1].title[this.props.lang]}</h2>
                                    <p>{this.props.home.info[1].content && this.props.home.info[1].content[this.props.lang]}</p>
                                </div>
                            </Col>
                            <Col sm="12" xs="12" md="12" xl="4" lg="4" >
                                <div className="box last">
                                    <img src={this.props.home.info[2].image} className="img-fluid" />
                                    <h2>{this.props.home.info[2].title && this.props.home.info[2].title[this.props.lang]}</h2>
                                    <p>{this.props.home.info[2].content && this.props.home.info[2].content[this.props.lang]}</p>

                                </div>
                            </Col>
                        </Row>

                    </Container>
                </div>
            </div>

        );
    }
}