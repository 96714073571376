import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Navigation from '../containers/navbar.js';
import Partneri from '../containers/partneriComponent.js';
import Map from '../containers/map.js';
import Footer from '../containers/footer.js';
import News from '../containers/newsComponent.js';


import {
    Col,
    Row,
    Container
} from 'reactstrap';

export default class NewsPage extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount(){
        window.scrollTo(0,0);
    }

    render() {
        const pocetna = 'news';
        return (
            <div>
                <div className="header_navigaton">

                    <Navigation {...this.props}></Navigation>

                    <Container>
                        <div className="asa">

                            <h2>{this.props.translate('NOVOSTI')}</h2>

                            <div className="position">
                                <h3><Link to={"/?lang="+this.props.lang}>{this.props.translate('POČETNA')}</Link>&nbsp;&nbsp;//&nbsp;&nbsp;{this.props.translate('novosti')}</h3>
                            </div>
                        </div>
                    </Container>


                </div>
                <div className="head">
                    <h4>{this.props.translate('NOVOSTI O NAMA')}</h4>
                    <p>{this.props.translate('Novosti o nama i našim uslugama')}</p>
                </div>

                <div className="news_wrapper">
                    <News {...this.props} pocetna={pocetna}></News>
                </div>

                <div className="partneri_about">
                    <Partneri {...this.props}></Partneri>
                </div>

                <div className="mapa">
                    <Map {...this.props}></Map>
                </div>
                <Footer {...this.props}></Footer>

            </div>
        );
    }
}