import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Navigation from '../containers/navbar.js';
import Partneri from '../containers/partneriComponent.js';
import Map from '../containers/map.js';
import Footer from '../containers/footer.js';
import GalleryComponent from '../containers/galleryComponent.js';


import {
    Container,
    Row,
    Col,
    Button
} from 'reactstrap';

export default class Gallery extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount(){
        window.scrollTo(0,0);
    }

    render() {
        return (
            <div>
                <div className="header_navigaton">

                    <Navigation {...this.props}></Navigation>

                    <Container>
                        <div className="asa">

                            <h2>{this.props.translate('GALERIJA')}</h2>

                            <div className="position">
                                <h3><Link to={"/?lang="+this.props.lang}>{this.props.translate('POČETNA')}</Link>&nbsp;&nbsp;//&nbsp;&nbsp;{this.props.translate('GALERIJA')}</h3>
                            </div>
                        </div>
                    </Container>


                </div>
                <Container>
                    <div className="head">
                        <h4>{this.props.translate('GALERIJA POSLOVNICE')}</h4>
                        <p>{this.props.translate('Galerija naše poslovnice u Derventi')}</p>
                    </div>

                </Container>
                <div className="gall">
                    <GalleryComponent {...this.props}></GalleryComponent>
                </div>
                <div className="partneri_about">
                    <Partneri {...this.props}></Partneri>
                </div>

                <div className="mapa">
                    <Map {...this.props}></Map>
                </div>
                <Footer {...this.props}></Footer>
            </div>
        );
    }
}
