import React from 'react';
import Isvg from 'react-inlinesvg';
import ClickOutHandler from 'react-onclickout';

import { NavLink as RRNavLink } from 'react-router-dom';
import logo_icon from '../images/logo.svg';








import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  Container,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavLink
} from 'reactstrap';


export default class navbar extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false

    };


    this.toggleDrop = this.toggleDrop.bind(this);
    this.state = {
      dropdownOpen: false
    };
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  toggleDrop() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  render() {
    return (
      <div>

        <Navbar className="navbar navbar-default" expand="lg">

          <Container>
            <NavbarBrand><Isvg src={logo_icon} /></NavbarBrand>


            <NavbarToggler onClick={this.toggle}><i className="fa fa-bars"></i></NavbarToggler>


            <Collapse isOpen={this.state.isOpen} navbar>


              <Nav className="navbar-nav" navbar>


                <NavItem>
                  <NavLink exact tag={RRNavLink} to={"/?lang=" + this.props.lang} activeClassName="activ">{this.props.translate('POČETNA')}</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink exact tag={RRNavLink} to={"/about?lang=" + this.props.lang} activeClassName="activ">{this.props.translate('O NAMA')}</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink exact tag={RRNavLink} to={"/partners?lang=" + this.props.lang} activeClassName="activ">{this.props.translate('PARTNERI')}</NavLink>
                </NavItem>
              </Nav>

              <Nav className="navbar-nav ml-auto">
                <NavItem>
                  <NavLink exact tag={RRNavLink} to={"/gallery?lang=" + this.props.lang} activeClassName="activ">{this.props.translate('GALERIJA')}</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink exact tag={RRNavLink} to={"/news?lang=" + this.props.lang} activeClassName="activ">{this.props.translate('NOVOSTI')}</NavLink>
                </NavItem>
                <NavItem className="no-ma">
                  <NavLink exact tag={RRNavLink} to={"/contact?lang=" + this.props.lang} activeClassName="activ">{this.props.translate('KONTAKT')}</NavLink>
                </NavItem>


              </Nav>

            </Collapse>


          </Container>

          <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDrop}>
            <DropdownToggle caret>
              {this.props.lang.toUpperCase()}
            </DropdownToggle>

            <DropdownMenu>
              <DropdownItem onClick={() => this.props.changeLanguage('sr')}>SR</DropdownItem>

              <DropdownItem onClick={() => this.props.changeLanguage('en')}>ENG</DropdownItem>
              <DropdownItem onClick={() => this.props.changeLanguage('de')}>DE</DropdownItem>

            </DropdownMenu>
          </Dropdown>

        </Navbar>


      </div>
    );
  }
}
