import React, { Component } from 'react';
import Navigation from '../containers/navbar.js';
import Kvalitet from '../containers/kvalitet.js';
import News from '../containers/newsComponent.js';
import Partneri from '../containers/partneriComponent.js';
import Map from '../containers/map.js';
import Footer from '../containers/footer.js';
import { Link } from 'react-router-dom';
import prva from '../images/prva.png';
import sl from '../images/sl.png';
import sl2 from '../images/sl2.png';
import Bacgroundslider1 from '../images/background_slider.png';
import about1 from '../images/about1.png';




import {
    Container,
    Col,
    Row,
    Button,

} from 'reactstrap';


export default class Page extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount(){
        window.scrollTo(0,0);
    }

    render() {


        let data = {};

        for(let i=0;i<this.props.pages.length;i++){
            if (this.props.pages[i]._id == this.props[0].match.params.id){
                data = this.props.pages[i];
                break;
            }
        }


        return (
            <div>
                <div className="header_navigaton">

                    <Navigation {...this.props}></Navigation>

                    <Container>
                        <div className="asa">

                            <h2>{data.title && data.title[this.props.lang]}</h2>

                            <div className="position">
                                <h3><Link to={"/?lang="+this.props.lang}>{this.props.translate('POČETNA')}</Link>&nbsp;&nbsp;//&nbsp;&nbsp;{data.title && data.title[this.props.lang]}</h3>
                            </div>
                        </div>
                    </Container>


                </div>
                <div className="lider">
                    <Container>
                        <Row>
                            <Col xl="6" lg="6" md="12" xs="12" sm="12">
                                <img src={data.image} className="img-fluid" />
                            </Col>
                            <Col xl="6" lg="6" md="12" xs="12" sm="12" dangerouslySetInnerHTML={{__html: data.content1 && data.content1[this.props.lang]}}>
                            </Col>
                        </Row>
                        <div className="lider2">

                            <Row className="no-gutters">
                                <Col xl={{ size: 6, order: 0 }} lg={{ size: 6, order: 0 }} md={{ size: 12, order: 1 }} xs={{ size: 12, order: 1 }} dangerouslySetInnerHTML={{__html: data.content2 && data.content2[this.props.lang]}}>


                                </Col>
                                <Col xl={{ size: 3, order: 1 }} lg={{ size: 3, order: 1 }} xs={{ size: 12, order: 0 }} sm="12" md={{ size: 6, order: 0 }}>
                                    <Col xl="12" md="12" lg="12" className="no-pa">
                                        <img src={data.images && data.images[0]} className="img-fluid slika" />
                                    </Col>
                                </Col>

                                <Col xl={{ size: 3, order: 1 }} lg={{ size: 3, order: 1 }} md={{ size: 6, order: 0 }} xs={{ size: 12, order: 0 }}>
                                    <Col xl="12" xs="12" sm="12" md="12" lg="12" className="no-pa">
                                        <img src={data.images && data.images[1]} className="img-fluid slika" />
                                    </Col>

                                    <Col xl="12" xs="12" sm="12" md="12" lg="12" className="no-pa">
                                        <img src={data.images && data.images[2]} className="img-fluid slika" />
                                    </Col>
                                </Col>

                            </Row>

                        </div>
                    </Container>
                </div>

                <Kvalitet {...this.props}></Kvalitet>

                <div className="novosti">
                    <div className="content">
                        <Container>
                            <div className="text">
                                <Row>
                                    <Col xl="3" xs="6" md="6" lg="6">
                                        <h4>{this.props.translate('Novosti')}</h4>
                                    </Col>
                                    <Col xl="6" xs="6" md="6" lg="6">
                                        <p>{this.props.translate('Novosti o nama i našim uslugama')}</p>
                                    </Col>
                                    <Col xl="3" xs="12" md="12" lg="12">
                                        <Link to={"/news?lang="+this.props.lang}><h5>{this.props.translate('ARHIVA NOVOSTI')}</h5></Link>
                                    </Col>
                                </Row>
                            </div>

                        </Container>
                    </div>
                    <div className="boxNews">
                        <News {...this.props}></News>
                    </div>

                </div>

                <div className="partneri_about">
                    <Partneri {...this.props}></Partneri>
                </div>

                <div className="mapa">
                    <Map {...this.props}></Map>
                </div>
                <Footer {...this.props}></Footer>

            </div>
        );
    }
}