import React from 'react';
import Isvg from 'react-inlinesvg';
import { Link } from 'react-router-dom';
import adidas from '../images/partneri/aiddas.png';
import meindl from '../images/partneri/meindl.png';
import bema from '../images/partneri/bema.png';
import haix from '../images/partneri/haix.png';





import {
    Container,
    Row,
    Col,
    Image,
    Button,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption


} from 'reactstrap';


export default class Partneri extends React.Component {
    constructor(props) {
        super(props);

    }

    render() {
        return (
            <div>
                <div className="partneri">
                    <Container>
                        <Row>
                            <Col xl="6" md="12" lg="12" xs="12" sm="12">
                                <h4>{this.props.translate('NAŠI PARTNERI')}</h4>
                                <p>{this.props.partners.content && this.props.partners.content[this.props.lang]}</p>
                                <Link to={"/partners?lang="+this.props.lang}><Button>{this.props.translate('DETALJNIJE')}</Button></Link>
                            </Col>


                            <Col xl="6">
                                <div className="bla">
                                    <Row>
                                        {
                                            this.props.partners.partners.map((item, idx) => {
                                                if (item.image){

                                                return (
                                                    
                                                    <Col xl="6" xs="12" sm="12" md="6" lg="6">
                                                        <div className="slika partner-image">
                                                            <img src={item.image} className="img-fluid " />
                                                        </div>
                                                    </Col>

                                                )
                                                }
                                            }
                                            )
                                        }
                                    </Row>
                                   
                                </div>
                            </Col>

                        </Row>
                    </Container>
                </div>
            </div>
        );
    }
}